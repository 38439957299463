<template>
	<main>
		<HeaderTab :title="componentTitle" />
		<div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
							<LoadingSpinner v-if="working" />
							<div v-else>
								CODE: {{ formatterActeTypeCode }}

								<!-- Choix du groupe qui en découle -->
								<div class="row" v-if="acte_groupes">
									<div class="form-group col-12">
										<label>{{ $t('acte_type.choisir_type') }}</label>
										<e-select
											v-model="acte_type_groupe"
											:options="acte_groupes"
											:allow-empty="false"
											:show-labels="false"
											label="actesgroupe_label"
											track-by="actesgroupe_code"
										/>
									</div>
								</div>

	                        	<!-- Choix du nom de l'acte type à créer -->
	                        	<div class="form-group">
	                        		<label>{{ $t('acte_type.nom_votre_acte_type') }}</label>
	                        		<input type="text" class="form-control" v-model="acte_type_name" placeholder='Ex: DG'>
	                        	</div>

								<!-- Choix du délai maximum de l'acte -->
								<div class="form-group">
	                        		<label>{{ $t('acte_type.votre_maxdelay') }}</label>
	                        		<input type="text" :class="maxdelayIsCorrect ? 'form-control' : 'form-control is-invalid'" v-model="acte_type_maxdelay" :placeholder="$t('acte_type.maxdelay_placeholder')">
	                        	</div>

								<!-- {{ questions }} -->

                                <!-- Création des questions -->
                                <draggable v-model="questions" v-if="form_ready">
                                    <div 
                                        v-for="question in questions"
                                        :key="`question-${question.question_index}`"
                                        class="acte_element mb-3 draggable"
                                        :class="{active: question.isActive}"
                                        draggable="true"
                                        @dragend="orderQuestions($event,false)"
									>

										<div class="row align-items-end mb-2" @click="focusQuestion(question)">
		                        			<div class="col acte_titre">
				                        		<label>{{ $t('acte_type.titre_question') }}</label>
												<input v-if="question.isActive" type="text" class="form-control" :placeholder="$t('acte_type.titre_question_placeholder')" v-model="question_libelle" :readonly="isWebServiceType" :ref="'inputQuestionLabel'+question.question_index">
				                        		<input v-else type="text" class="form-control" :placeholder="$t('acte_type.titre_question_placeholder')" :value="question.question_libelle">
		                        			</div>
		                        			<div class="col acte_type">
				                        		<label>{{ $t('acte_type.type_question') }}</label><br/>
				                        		<b-dropdown
													no-caret
													toggle-class="text-left custom-select"
													dropdown-class="w-100" class="w-100"
													>
				                        			<template slot="button-content" >
														{{ dropdownText }}
														<!-- <font-awesome-icon :icon="['fal', 'caret-down']" /> -->
													</template>

                                                    <b-dropdown-item v-for="input_type in input_types" :key="input_type.typeinput_libelle" @click="selectInputType(input_type)">
                                                        <font-awesome-icon :icon="input_type.typeinput_icon" />
                                                        {{ $t('question.'+input_type.typeinput_libelle) }}
                                                    </b-dropdown-item>

												</b-dropdown>
		                        			</div>
		                        			<div class="col-auto acte_delete">
		                        				<button @click="deleteQuestion(question)" class="btn btn-secondary"><font-awesome-icon :icon="['fal', 'trash-alt']" /></button>
		                        			</div>

		                        		</div>

										<!-- Pour pouvoir gérer les réponses possibles -->
										<ManageValues
											v-if="selected_input_type && question.isActive"
											:values.sync="question_valeurs"
											:icon="faIconValues"
											:type="selected_input_type.typeinput_code"
										/>
										<ViewValues
											v-show="!question.isActive"
											:values="question.question_questiondata"
											:icon="question.question_icon"
											:type="question.question_questiontype"
										/>
		                        	</div>
								</draggable>
								
	                        	<div v-if="isAdmin">
									<b-form-checkbox v-model="global">
										{{ $t('acte_type.global') }}
									</b-form-checkbox>
								</div>

	                        	<div class="mt-2">
									<b-form-checkbox v-model="need_ordonnance">
										{{ $t('acte_type.need_ordonnance') }}
									</b-form-checkbox>
								</div>

								<!-- Boutons d'actions -->
	                        	<div class="text-center mt-4">
	                        		<button class="btn btn-secondary rounded-pill mr-3" @click="addQuestion">
	                        			<font-awesome-icon :icon="['fal', 'plus']" /> {{ $t('acte_type.ajouter_element') }}
	                        		</button>
	                        		<button class="btn btn-primary rounded-pill" @click="saveActeTypeForm" :disabled="!maxdelayIsCorrect">
                						<font-awesome-icon v-if="isProcessing" :icon="['fas', 'spinner']" pulse />
	                        			<font-awesome-icon v-else :icon="['fal', 'save']" />
										{{ $t('acte_type.save_acte_type') }}
	                        		</button>
	                        	</div>
	                        </div>
                        </div>
                    </div>
		        </div>
		    </div>
		</div>

	</main>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import ActeType from '@/mixins/ActeType.js'
	import Actes from '@/mixins/Actes.js'
    import Question from '@/mixins/Question.js'	
    import User from "@/mixins/User.js"
	import { PROGRESS_STATES } from '@/assets/js/sync/states'


	export default {
		name: "ActeTypeAjout",
		mixins: [Question, Actes, ActeType, User],
		data () {
			return {
				componentTitle: this.getTrad('acte_type.ajout_acte_type'),
				input_types: null,// La liste des types de questions
				working: false,//S'il y a un traitement en cours, variable pour masquer le formulaire et afficher le spinner d'attente
				form_ready: false,//
				acte_groupes: null,//
				from_asset: null,//
				acte_type_id: null,//Id de l'acte-type, si modification d'un existant
				type_code_webservice: 'WBS',//

				//Valeurs utilisateur pour l'acte
				acte_type_name: '',//Nom de l'acte
				acte_type_maxdelay: '', // Délai maximum de l'acte
				acte_type_groupe: null,//Type de l'acte (groupe)
				acte_type_code: null,//Type de l'acte (groupe)
				questions: [],// Le tableau interne des questions

				//Valeurs utilisateur pour la question en cours
				question_index: 1, // Index (position) de la question. Prévoir de le modifier si on change l'ordre de la dernière question
				question_libelle: '', //Titre de la question
				selected_input_type: null,//Le type de question (index dans la liste input_types ?)
				question_valeurs: [], //Valeurs
				global: false,
				need_ordonnance: false,
				isProcessing: false
			}
		},
		mounted() {
			this.from_asset = this.$route.params.from_asset
			this.acte_type_id = this.$route.params.acte_type_id
			this.init_component()
		},
		methods: {
			async init_component() {
				this.input_types = await this.loadTypesInput()
				this.acte_groupes = await this.getActesGroupes(true, true)

				if(this.acte_type_id) {
					await this.loadActeTypeEdit()
				}
				else {
					this.addQuestion()
				}

				this.form_ready = true

			},

			selectInputType(item) {
				this.selected_input_type = item
			},

			// Mise à jour des index des questions selon le placement visuel
			orderQuestions() {
				for (var key in this.questions) {
					this.questions[key].question_index = key
				}
				this.questions.sort(function(a, b){return a.question_index-b.question_index})
			},

			// Lorsque l'on change de question, on enregistre les modifs de l'actuelle avant de changer
			async focusQuestion(nouvelle_question, verification_question_precedente=true) {
				// Si la question sélectionnée est déjà active, ne rien faire
				if(nouvelle_question && nouvelle_question.isActive) return

				// S'il ne s'agit pas de la première question, on vérifie que la question précédemment active est valide
				if(verification_question_precedente && !this.checkQuestionSubmit()) {
					this.failureToast('toast.info_donnees_manquantes')
					return
				}

				// Sauvegarder la (ou les) question active et la rendre inactive
				this.questions.forEach(question => {
						if(question.isActive){
							question.isActive = false
							this.saveQuestion(question)
						}
					},this
				)

				// On active la question sur laquelle on a cliqué
				nouvelle_question.isActive = true
				this.autocompleteForm(nouvelle_question)

				const ref = 'inputQuestionLabel'+nouvelle_question.question_index
				await Common.waitUntil(() => (this.$refs[ref]))
				this.$refs[ref][0].focus()
			},

			// Vérifier que la question en cours de création est valide
			checkQuestionSubmit() {
				// S'il n'y a aucune question, valider
				if(this.questions.length==0) return true
				const libelle_ok = this.question_libelle ? true : false
				const input_type_ok = this.selected_input_type ? true : false
				const valeurs_ok = this.question_valeurs!=null && this.question_valeurs.length > 0 ? true : false
				return libelle_ok && input_type_ok && valeurs_ok
			},

			addQuestion() {
				if(this.questions.length > 0 && !this.checkQuestionSubmit()) {
					this.failureToast('toast.question.info_donnees_manquantes')
					return
				}

				// On créé l'objet et ses attributs
				let tmp = {}
				tmp.question_libelle = ''
				tmp.question_placeholder = ''
				tmp.question_index = this.question_index
				tmp.question_questiontype = ''
				tmp.question_questiondata = {}
				tmp.question_icon = ''// Pour l'affichage, on garde de côté l'icone de type d'input
				tmp.isActive = false

				this.questions.push(tmp)
				this.orderQuestions()
				this.focusQuestion(tmp, false)// On active la question qu'on vient d'ajouter
			},

			deleteQuestion(question) {
				// On récupère l'index de la question dans le tableau, pour le supprimer
				this.questions.splice(this.questions.indexOf(question), 1)
				//On réindexe les questions
				this.orderQuestions()
				// On active la dernière question
				if(this.questions.length > 0) this.focusQuestion(this.questions[this.questions.length-1],false)
			},

			/* Sauvegarde de l'objet question dans le tableau d'objets */
			saveQuestion(question) {
				//if(this.selected_input_type===null) return;
				const input_code = this.selected_input_type.typeinput_code
				const type_data = (input_code == this.type_code_webservice ? 'url' : 'data')

				question.question_libelle = this.question_libelle
				question.question_questiontype = this.selected_input_type.typeinput_code
				question.question_questiondata = { type: type_data, 'data': this.question_valeurs }
				question.question_icon = this.faIconValues
			},

			/* Vide le formulaire global */
			resetForm() {
				this.question_libelle = ''
				this.selected_input_type = null
				this.question_valeurs = []
			},

			/* Remplit le form de question à partir d'une question donnée */
			autocompleteForm(question) {
				this.question_libelle = question.question_libelle
				this.question_valeurs = question.question_questiondata.data
				const selected_input_type = this.input_types.filter(type => type.typeinput_code == question.question_questiontype)
				if(selected_input_type.length > 0) {
					this.selected_input_type = selected_input_type[0]
				}
				this.question_index = question.question_index
			},

			/* Sauvegarde sur la bdd de l'acte type */
			async saveActeTypeForm() {
				if(!this.checkActeTypeForm()) {
					this.failureToast('toast.acte_type.info_donnees_manquantes')
					return
				}
				
				// Pour que le sync.force fonctionne
				if(this.isSynchronizing) {
					this.failureToast('toast.acte_type.synchronizing')
					return
				}

				// On sauvegarde les paramètres de la question active
				const last_question = this.questions.filter(question => question.isActive == true)
				if(last_question.length > 0) {
					this.saveQuestion(last_question[0])
				}

				try {
					this.isProcessing = true

					// On commence par sauvegarder l'acte type en lui même en bdd (update éventuel automatique)
					let result = await this.saveActeType(this.acte_type_groupe.actesgroupe_code, this.acte_type_name, this.formatterActeTypeCode, this.acte_type_id, this.global, this.need_ordonnance)

					// Si tout va bien, on sauvegarde les éventuelles questions en bdd
					const questions_filled = this.prepareQuestionsTab(this.questions)
					if(result && result.retour) {
						const acte_type_id = result.retour.actestype_id
						await this.saveActeTypeQuestions(acte_type_id, questions_filled, this.global)
						if(this.acte_type_maxdelay !== ''){
							await this.saveActesMaxdelay([acte_type_id], this.acte_type_maxdelay)
						}
					}

					// On attend pour que la synchro prenne en compte les modifs envoyées à laravel
					await new Promise((res, _) => {
						window.setTimeout(() => res(), 500)
					})

					this.successToast('toast.info_save_succes')
					this.redirectView()
				}
				catch (e) {
					console.error(e)
					this.failureToast('toast.info_save_failed')
				}
			},

			/* Vérification du formulaire global */
			checkActeTypeForm() {
				// On vérifie les infos de base de l'acte type, et que la question en cours est viable
				return this.acte_type_name && this.acte_type_groupe && this.checkQuestionSubmit()
			},

			/* Si on est sur une édition d'acte type, on charge les infos pour auto complete les form */
			async loadActeTypeEdit() {
				//Chargement de l'acte type dans l'interface
				const promise_acte_type = new Promise((res, rej) => {
					this.getActeTypeFromId(this.acte_type_id)
					.then(async acte_type => {
						this.acte_type_groupe = this.acte_groupes.find(ag => ag.actesgroupe_code == acte_type.actestype_codegroupe)
						this.acte_type_name = acte_type.actestype_label
						this.acte_type_code = acte_type.actestype_code
						this.global = acte_type.actestype_licencekey === null || acte_type.actestype_licencekey === ""
						this.need_ordonnance = acte_type.actestype_needordonnance

						// vérifie l'accès à l'acte type
						let user = await this.getUserAccessRights();
						if((this.global && user.indexOf("ADMN") == -1) || (!this.global && acte_type.actestype_licencekey != this.getConfig('licence_key'))){
							this.failureToast('toast.info_page_interdite')
							this.$router.push({name: 'acteTypeListe'})
						}
						res()
					})
					.catch(e => {
						console.error("ActeTypeAjout::init_component => ", e)
						this.failureToast('toast.info_lecture_failed')
						rej()
					})
				})

				//Chargement des questions dans l'interface
				const promise_acte_question = new Promise((res, rej) => {
					this.getQuestions('horse_actes_type', this.acte_type_id)
					.then(acte_type_questions => {
						return this.formatDistantQuestionToLocal(acte_type_questions)
					})
					.then(questions => {
						this.questions = questions
						if(this.questions.length > 0){
							const last_question = this.questions[this.questions.length-1]
							last_question.isActive = true
							this.autocompleteForm(last_question)
						}
						res()
					})
					.catch(e => {
						console.error("ActeTypeAjout::init_component => ", e)
						this.failureToast('toast.info_lecture_failed')
						rej()
					})
				})

				const tab_promises = [promise_acte_type, promise_acte_question]
				return await Promise.all(tab_promises)
			},

			redirectView() {
				this.working = true

				// On reload la config d'actes types
				this.reloadActesType()
					.then(() => {
						// On fait la redirection
						this.$router.push({ name: 'acteTypeListe' })
					})
			},

		},
		components: {
			draggable: () => import('vuedraggable'),
			HeaderTab: () => import('@/components/HeaderTab'),
            ManageValues: () => import('@/components/Questions/ManageValues'),
            ViewValues: () => import('@/components/Questions/ViewValues'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		},
		watch: {
			'selected_input_type': {
				handler(val) {
					// Si on est sur un type WBS (webservice), on bloque la possibilité de donner un nom à la question
					if(this.isWebServiceType) {
						this.question_libelle = this.getTrad('question.nom_prerempli')
					}
				},
				deep: true
			}
		},
		computed: {
			dropdownText() {
				if(this.selected_input_type) {
					return this.getTrad('question.'+this.selected_input_type.typeinput_libelle)
				}
				return this.getTrad('global.selectionnez_element')
			},
			faIconValues() {
				if(this.selected_input_type) {
					return this.selected_input_type.typeinput_icon
				}
				return ['fal', 'times']
			},
			formatterActeTypeCode() {
				if(this.acte_type_id) {
					return this.acte_type_code
				}

				if(!this.acte_type_name) {
					return
				}

				const rexeg_replace = /[|&;$%@"<>()+,é'§è!ç°^¨`ù=+:/.?#-]/g
				let clean_text = this.acte_type_name.replace(rexeg_replace, "")
				clean_text = clean_text.replace(/[ ]+/g, "_")
				clean_text = clean_text.toUpperCase()
				return this.acte_type_groupe.actesgroupe_code + '_' + clean_text
			},
			isWebServiceType() {
				if(this.selected_input_type) {
					return this.selected_input_type.typeinput_code == this.type_code_webservice
				}
				return false
			},
			isAdmin() {
				return this.$store.state.userAccess.hasAdminAccess
			},
			isSynchronizing() {
				return this.$store.state.sync.progressState != PROGRESS_STATES.COMPLETED
			},
			maxdelayIsCorrect(){
				let formattedMaxDelay = this.formatMaxdelay(this.acte_type_maxdelay)
				return this.checkMaxdelayFormat(formattedMaxDelay)
			}
		} 
	}
</script>
